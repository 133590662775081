<template>
    <div id="login-section" class="align-items-center d-flex">
        <div class="container">
            <div class="row justify-content-center">
                <div
                    class="bg-opacity-25 bg-primary border border-primary col-10 col-sm-5 my-5 p-sm-5 px-2 py-4 rounded">
                    <form @submit.prevent="login()" class="needs-validation" novalidate>
                        <h3 class="text-center mb-4 text-primary">Login</h3>
                        <div class="mb-3">
                            <input v-model="form.email" type="email" class="form-control text-center"
                                   :class="{'is-invalid' : error}"
                                   id="email" required placeholder="USERNAME">
                        </div>
                        <div class="mb-3">
                            <input v-model="form.password" type="password" class="form-control text-center"
                                   :class="{'is-invalid' : error}" id="password" required placeholder="PASSWORD">
                        </div>
                        <div class="col-12">
                            <button type="submit" class="btn btn-primary w-100 text-light">Login</button>
                        </div>
                        <div v-if="error" class="invalid-feedback d-block text-center">
                            {{ error_msg }}
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ApiService          from "@/services/api.service";
import JwtService          from "@/services/jwt.service";
import NotificationService from "../../services/notification.service";
import Menu                from "../web/layouts/Menu";

export default {
    name      : "Login",
    components: {Menu},
    data      : () => ({
        form     : {
            email   : "",
            password: ""
        },
        error    : false,
        error_msg: '',
    }),
    methods   : {
        login() {
            if (process.env.VUE_APP_ENV === 'live') {
                fetch('https://google.com', {
                    method: 'GET', // *GET, POST, PUT, DELETE, etc.
                    mode  : 'no-cors',
                }).then((result) => {
                    this.loginSubmit();
                }).catch(e => {
                    this.error     = true;
                    this.error_msg = 'No Internet connection';
                    NotificationService.error('No Internet connection');
                })
            } else {
                this.loginSubmit();
            }
        },
        loginSubmit() {
            this.error = false;
            ApiService.post('/login', this.form).then((res) => {
                JwtService.saveToken(res.data.data.access_token);
                localStorage.setItem("expires_at", res.data.data.expires_at);
                ApiService.init();
                this.$router.push({name: "adminDashboard"});
                NotificationService.success(res.data.message);
            }).catch(error => {
                this.error     = true;
                this.error_msg = 'Username or Password is wrong';
                NotificationService.error('Username or Password is wrong');
                // if (error.response.status === 422) {
                //     this.errors = error.response.data.errors;
                // }
            });
        }
    }
};
</script>
